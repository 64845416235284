<template>
  <div
    class="product-reco-single-focus grid md:gap-x-1 md:grid-cols-2 items-stretch mb-1"
  >
    <div class="product-reco-single-focus__media-wrapper mb-1 md:mb-0">
      <slot name="media" />
    </div>
    <slot name="product-card" />
  </div>
</template>
